<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-between">
        <h5 class="right">View Address</h5>
        <button
          class="left"
          @click="() => this.$bvModal.show('bv-add-address')"
        >
          Add Address
        </button>
        <b-modal
          id="bv-add-address"
          size="lg"
          :hide-footer="true"
          :hide-header="true"
          :no-close-on-esc="true"
          :no-close-on-backdrop="true"
          centered
          header-class="my-class"
          body-class="p-0"
        >
          <template v-if="$options.components['address-add-address-modal']">
            <address-add-address-modal />
          </template>
        </b-modal>
      </div>
    </b-container>
    <div class="body">
      <div v-for="(address, index) in deliveryAddress" :key="index">
        <div>
          <b-card-text>
            <div class="d-flex justify-content-between">
              <p :value="address" class="heading-name">
                {{ address.fullName }}
                <large
                  v-if="address.isDefault"
                  class="default-address-class ml-5"
                >
                  Default
                </large>
              </p>
              <div class="">
                <span
                  v-if="
                    $store.state['address'].deleteAddressLoading != address._id
                  "
                  class="float-right"
                >
                  <img
                    @click="() => deleteAddress(address._id)"
                    style="cursor: pointer"
                    src="img/address/Trash.png"
                    alt=""
                  />
                  <img
                    class="edit-icon-style"
                    @click="
                      () => {
                        editAddressClick(address);
                        $bvModal.show(address._id);
                      }
                    "
                    src="img/EditIcon.png"
                    alt=""
                  />
                  <b-modal
                    :id="address._id"
                    size="lg"
                    :hide-footer="true"
                    :hide-header="true"
                    :no-close-on-esc="true"
                    :no-close-on-backdrop="true"
                    centered
                    header-class="my-class"
                    body-class="p-0"
                  >
                    <template
                      v-if="$options.components['address-edit-address-modal']"
                    >
                      <address-edit-address-modal
                        ref="editAddressRef"
                        :address="address"
                      />
                    </template>
                  </b-modal>
                </span>
                <span
                  v-if="
                    $store.state['address'].deleteAddressLoading == address._id
                  "
                  class="float-right"
                >
                  <b-spinner
                    v-for="i in 3"
                    :key="i"
                    class="mr-1"
                    small
                    label="Small Spinner"
                    type="grow"
                  ></b-spinner>
                </span>
              </div>
            </div>
            <p style="" class="mr-1" alt>
              {{ address.houseNumber }}, {{ address.street1 }},
            </p>
            <p style="line-height: 10px" width="10px" class="mr-1" alt>
              {{ address.street2 }}, {{ address.city }},
            </p>
            <p style="line-height: 10px" width="10px" class="mr-1" alt>
              {{ address.state }},
              {{ address.zip }}
            </p>
          </b-card-text>

          <hr v-if="index < len - 1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "View_Address_Edit",
  computed: {
    ...mapState({
      deliveryAddress: (state) => state.address.deliveryAddress,
    }),
  },
  data: () => {
    return {
      len: null,
    };
  },
  methods: {
    async deleteAddress(e) {
      this.$bvModal
        .msgBoxConfirm("Do you want to delete your address?", {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$store.dispatch("address/deleteAddress", e);
            this.$swal({
              text: "You Address successfully deleted",
              type: "success",
              title: "Success",
              timer: 900,
            });
          }
        });
    },
    editAddressClick(e) {
      this.$emit("editAddress", e);
    },
    addAddress() {
      this.$bvModal.show("bv-add-address");
    },
    async fetchAddressList() {
      await this.$store.dispatch("address/fetchDeliveryAddress");
    },
  },

  async mounted() {
    await this.fetchAddressList();
    this.len = this.deliveryAddress.length;
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
.left {
  color: rgba(255, 115, 0, 0.897);
  font-size: small;
  border: 1px solid rgba(255, 115, 0, 0.897);
  border-radius: 12px;
  padding: 5px;
}
.right {
  font-weight: bold;
  font-size: medium;
}
.edit-icon-style {
  cursor: pointer;
  height: 35px;
  width: 28px;
  margin-top: 6px;
  margin-left: 10px;
}
.body {
  background: #f8f8f8;
  padding: 15px;
  border-radius: 15px;
  width: 100%;
}
.check {
  color: rgba(255, 115, 0, 0.897);
  margin-left: auto;
}

p.heading-name {
  font-weight: 600;
  font-size: 18px;
}
.heading-name .default-address-class {
  font-size: 14px;
  font-weight: normal;
}
</style>
